function check_updates() {
	var $currentVersion = $('body').data('version');
	var $currentBuild = $('body').data('build');
	var $modal = $('#modal_updateNotify').modal({show: false});

	// TODO: Include jts3servermod update https://www.stefan1200.de/versioncheck/JTS3ServerMod.version2
	$.get('https://static.monster010.de/jts3bootinterface/version.txt', function (data) {
		var $new = data.split(' - ');
		var $vc_version = version_compare($currentVersion, $new[0].trim());
		var $vc_build = version_compare($currentBuild, $new[1].trim());

		if ($vc_version === $currentVersion && $vc_build === $currentBuild) {
			$modal.find('.modal-body').html('<p><b>Design Update:</b> There is a update available!</p>'); // TODO: Show Version
			$modal.find('#modal_updateNotify_download').attr('href', 'https://download.monster010.de/jts3bootinterface/release/JTS3BootInterface-latest.zip');
			$modal.modal('show');
		} else if ($vc_version === $currentVersion && $vc_build === $currentBuild) {
			$modal.find('.modal-body').html('<p><b>Update:</b> There is a update available for Bootinterface and JTS3ServerMod!</p>'); // TODO: Show Version
			$modal.find('#modal_updateNotify_download').attr('href', 'https://www.stefan1200.de/forum/index.php?topic=84.0');
			$modal.modal('show');
		}
	});
}


function version_compare(v1, v2, options) {
	var lexicographical = (options && options.lexicographical) || false,
		zeroExtend = (options && options.zeroExtend) || true,
		v1parts = (v1 || "0").toString().split('.'),
		v2parts = (v2 || "0").toString().split('.');

	function isValidPart(x) {
		return (lexicographical ? /^\d+[A-Za-zαß]*$/ : /^\d+[A-Za-zαß]?$/).test(x);
	}

	if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
		return NaN;
	}

	if (zeroExtend) {
		while (v1parts.length < v2parts.length) v1parts.push("0");
		while (v2parts.length < v1parts.length) v2parts.push("0");
	}

	if (!lexicographical) {
		v1parts = v1parts.map(function (x) {
			var match = (/[A-Za-zαß]/).exec(x);
			return Number(match ? x.replace(match[0], "." + x.charCodeAt(match.index)) : x);
		});
		v2parts = v2parts.map(function (x) {
			var match = (/[A-Za-zαß]/).exec(x);
			return Number(match ? x.replace(match[0], "." + x.charCodeAt(match.index)) : x);
		});
	}

	for (var i = 0; i < v1parts.length; ++i) {
		if (v2parts.length == i) {
			return 1;
		}

		if (v1parts[i] == v2parts[i]) {
			continue;
		} else if (v1parts[i] > v2parts[i]) {
			return 1;
		} else {
			return -1;
		}
	}

	if (v1parts.length != v2parts.length) {
		return -1;
	}

	return 0;
}