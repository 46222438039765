var $modal_manager_remove = $('#modal_manager_remove').modal({show: false});
var $modal_manager_rename = $('#modal_manager_rename').modal({show: false});

$('*[data-bot="manage"]').click(function(e) {
	e.preventDefault();

	var $this = $(this);
	var $id = $(this).data('botid');
	var $cmd = $(this).data('command');
	var $function = $(this).data('botfunction');

	var $data = {
		'mode': 'botmanage',
		'command': $cmd,
		'botid': $id,
		'prefix': $function,
	};

	$.ajax({
		url: 'ajax.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		beforeSend: function() {
			$('#fullLoading').removeClass('hidden');
		},
		success: function(data) {
			if($cmd == 'activateFunction') {
				$this.data('command', 'deactivateFunction');
				$this.html('<i class="fas fa-stop fa-fw"></i>');
				$('i[data-command-status="' + $function + '"]').addClass('green').removeClass('red');
			} else if($cmd == 'deactivateFunction') {
				$this.data('command', 'activateFunction');
				$this.html('<i class="fas fa-play fa-fw"></i>');
				$('i[data-command-status="' + $function + '"]').removeClass('green').addClass('red');
			}

			$('#fullLoading').addClass('hidden');
			$.notify(data.status, data.message);
		}
	});
});

$('*[data-bot="manage_remove"]').click(function(e) {
	e.preventDefault();

	var $id = $(this).data('botid');
	var $function = $(this).data('function');
	var $prefix = $(this).data('botfunction');

	$modal_manager_remove.find('#botmanager_remove_function_form').data('botid', $id);
	$modal_manager_remove.find('#functionclass').html($function);
	$modal_manager_remove.find('#functionprefix').html($prefix);
	$modal_manager_remove.modal('show');
});

$('*[data-bot="manage_rename"]').click(function(e) {
	e.preventDefault();

	var $id = $(this).data('botid');
	var $function = $(this).data('function');
	var $prefix = $(this).data('botfunction');

	$modal_manager_rename.find('#botmanager_rename_function_botid').val($id);
	$modal_manager_rename.find('#functionclass').html($function);
	$modal_manager_rename.find('#functionprefix').html($prefix);
	$modal_manager_rename.modal('show');
});

$('#botmanager_remove_function_cancel').click(function(e) {
	$modal_manager_remove.modal('hide');
});

$('#botmanager_rename_function_cancel').click(function(e) {
	$modal_manager_rename.modal('hide');
});

$('#botmanager_remove_function_form').submit(function(e) {
	e.preventDefault();

	var $id = $(this).data('botid');
	var $function = $(this).find('#functionprefix').html();
	var $class = $(this).find('#functionclass').html();

	var $data = {
		'mode': 'botmanage',
		'command': 'removeFunction',
		'botid': $id,
		'prefix': $function,
		'class': $class
	};

	$.ajax({
		url: 'ajax.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		beforeSend: function() {
			$modal_manager_remove.modal('hide');
			$('#fullLoading').removeClass('hidden');
		},
		success: function(data) {
			if(data.status == 'success')
				$('*[data-prefix="' + $function + '"]').slideUp('slow', function() {
					$(this).remove();
				});
			$('#fullLoading').addClass('hidden');
			$.notify(data.status, data.message);
		}
	});
});

$('#botmanager_rename_function_form').submit(function(e) {
	e.preventDefault();

	var re = /[^A-Za-z0-9\-\_]+/i;

	if(!re.test($('#modal_manager_rename_prefix').val())) {
		var $id = $('#botmanager_rename_function_botid').val();
		var $function = $(this).find('#functionprefix').html();
		var $class = $(this).find('#functionclass').html();
		var $newprefix = $('#modal_manager_rename_prefix').val();

		var $data = {
			'mode': 'botmanage',
			'command': 'renameFunction',
			'botid': $id,
			'prefixold': $function,
			'prefixnew': $newprefix,
			'class': $class
		};

		$.ajax({
			url: 'ajax.php',
			cache: false,
			data: $data,
			dataType: 'json',
			method: 'POST',
			beforeSend: function() {
				$modal_manager_rename.modal('hide');
				$('#fullLoading').removeClass('hidden');
			},
			success: function(data) {
				$('#fullLoading').addClass('hidden');

				if(data.status == 'nounique') {
					$('#modal_manager_rename_prefix').addClass('is-invalid');
					$('#modal_manager_rename_prefix_feedback').html(data.message);
					$modal_manager_rename.modal('show');
				} else {
					if(data.status == 'success') {
						setTimeout(function() {
							window.document.location.reload();
						}, 5000);
					}
					$.notify(data.status, data.message);
				}
			}
		});
	}
});