$.fn.genAlert = function(type, message) {
	$(this).html('<div class="alert alert-' + type + '" role="alert">' + message + '</div>');
	return $(this);
};

$.fn.genAlertTitle = function(type, title, message) {
	$(this).html('<div class="alert alert-' + type + '" role="alert"><h4>' + title + '</h4>' + message + '</div>');
	return $(this);
};

$.extend({
	notify: function(type, message) {
		if(typeof type === 'object') {$.notify(type.type, type.message); return;}

		toastr.options = {
			"closeButton": false,
			"progressBar": true,
			"onclick": null,
			"newestOnTop": true,
			"preventDuplicates": false,
			"positionClass": "toast-top-right",
			"showDuration": "5000",
			"hideDuration": "10000",
			"timeOut": "7000",
			"extendedTimeOut": "1000",
			"showEasing": "swing",
			"hideEasing": "linear",
			"showMethod": "fadeIn",
			"hideMethod": "fadeOut"
		};

		if(type === "success") {
			toastr.success(message);
		} else if(type === "warning") {
			toastr.warning(message);
		} else if(type === "error") {
			toastr.error(message);
		} else {
			toastr.info(message);
		}
	},
	notify_title: function(type, title, message) {
		if(typeof type === 'object') {$.notify_title(type.type, type.title, type.message); return;}

		toastr.options = {
			"closeButton": false,
			"progressBar": true,
			"onclick": null,
			"newestOnTop": true,
			"preventDuplicates": false,
			"positionClass": "toast-top-right",
			"showDuration": "5000",
			"hideDuration": "10000",
			"timeOut": "7000",
			"extendedTimeOut": "1000",
			"showEasing": "swing",
			"hideEasing": "linear",
			"showMethod": "fadeIn",
			"hideMethod": "fadeOut"
		};

		if(type === "success") {
			toastr.success(message, title);
		} else if(type === "warning") {
			toastr.warning(message, title);
		} else if(type === "error") {
			toastr.error(message, title);
		} else {
			toastr.info(message, title);
		}
	}
});