$.fn.shake = function(interval,distance,times) {
	interval = typeof interval == "undefined" ? 100 : interval;
	distance = typeof distance == "undefined" ? 10 : distance;
	times = typeof times == "undefined" ? 3 : times;

	var $target = $(this);

	$target.css('position', 'relative');

	for(var iter = 0; iter < (times + 1); iter++) {
		$target.animate({left: ((iter%2==0 ? distance : distance*-1))}, interval);
	}

	return $target.animate({left: 0}, interval);
};