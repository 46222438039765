$('#botlist').DataTable({
	"info": false,
	"paging": false,
	"columnDefs": [
		{"orderable": false, "targets": [0, 5]}
	],
	"order": [[1, "desc"]]
});

$('#botlist_admin').DataTable({
	"info": false,
	"paging": false,
	"columnDefs": [
		{"orderable": false, "targets": [0, 6]}
	],
	"order": [[1, "desc"]]
});


$('[data-toggle="tooltip"]').tooltip();
$('[data-toggle="popover"]').popover();