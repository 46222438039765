$('*[data-bot="command"]').click(function(e) {
	e.preventDefault();

	var $id = $(this).data('botid');
	var $cmd = $(this).data('botcmd');

	requestBotCommand($id, $cmd);
});

function requestBotStatusHeader(botid, loadingText) {
	if (loadingText != null) {
		$('#botstatus').html(loadingText).css('cursor', 'wait');
		$('#botstatus2').html(loadingText).css('cursor', 'wait');
		$('#botstatus_buttons').html(loadingText).css('cursor', 'wait');
	}

	var $data = {
		'mode': 'botstatusheader',
		'botid': botid
	};

	$.ajax({
		url: 'ajax.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		success:  incomingBotStatusHeader,
		error: incomingBotStatusHeader
	});

	setTimeout(requestBotStatusHeader, 10000, botid, null);
}

function requestBotCommand(botid, command) {
	var $data = {
		'mode': 'botcommand',
		'command': command,
		'botid': botid
	};

	$.ajax({
		url: 'ajax.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		beforeSend: function() {
			$('#fullLoading').removeClass('hidden');
		},
		success: function(data) {
			$('#fullLoading').addClass('hidden');
			$.notify(data.status, data.message);
		}
	});
}

function requestBotStatusList(botlistuserid, statusOff, statusOn) {
	var $data = {
		'mode': (botlistuserid == -1) ? "botstatusall" : "botstatus",
		'botlistuserid': botlistuserid
	};

	$.ajax({
		url: 'ajax.php',
		cache: false,
		data: $data,
		dataType: 'json',
		method: 'POST',
		success: function(data, status) {
			incomingBotStatusList(data, status, statusOn, statusOff);
		},
		error: function(data, status) {
			incomingBotStatusList(data, status, statusOn, statusOff);
		},
	});

	setTimeout(requestBotStatusList, 10000, botlistuserid, statusOff, statusOn);
}

function incomingBotStatusHeader(data, status) {
	var $botStatusText = $('#botstatus');
	var $botStatusText2 = $('#botstatus2');
	var $botStatusButtons = $('#botstatus_buttons');
	var $botStartDate = $('#botstartdate');
	var $botLastSuccessfulConnect = $('#lastsuccessfulconnect');
	var $botLogStatus = $('#botlogstatus');


	if(status === 'success') {
		if(data.length < 7) {
			$botStatusText.html("Error " + data).css('cursor', 'auto');
			$botStatusText2.html("Error " + data).css('cursor', 'auto');
			$botStatusButtons.html("").css('cursor', 'auto');
		} else {
			$botStatusText.html(data.botstatus).css('cursor', 'auto');
			$botStatusText2.html(data.botstatus).css('cursor', 'auto');
			$botStatusButtons.html(data.botcommands).css('cursor', 'auto');
			$botStartDate.html(data.startdate);
			$botLastSuccessfulConnect.html(data.successfulconnectdate);
			$botLogStatus.html(data.warncount + " / " + data.errcount).attr('class', data.cssclass);
		}
	} else {
		$botStatusText.html("AJAX Error").css('cursor', 'auto');
		$botStatusText2.html("AJAX Error").css('cursor', 'auto');
		$botStatusButtons.html("").css('cursor', 'auto');
	}
}

function incomingBotStatusList(data, status, statusOff, statusOn) {
	if(status === 'success') {
		if(data.length < 7) {
			console.log("AJAX Error: incomingBotStatusList");
		} else {
			for(let key in data) {
				if(data.hasOwnProperty(key)) {
					var $botStatusImage = $('#' + key);

					if(data[key] === "0") {
						$botStatusImage.attr('src', "assets/images/bullet_red.png")
							.attr('title', statusOff)
							.attr('alt', statusOff);
					} else if(data[key] === "1") {
						$botStatusImage.attr('src', "assets/images/bullet_green.png")
							.attr('title', statusOn)
							.attr('alt', statusOn);
					}
				}
			}
		}
	}
}

function showCharCount(object, targetid) {
	var $targetDIV = $('#' + targetid + '_div');
	var $target = $('#' + targetid);

	if ($targetDIV.css('display') === 'none')
		$targetDIV.css('display', '');

	$target.html(object.value.length);
}

function reloadSendButton() {
	var now = new Date();
	$('#sendbutton').attr('src', "antispam.php?" + now.getTime());
}

function toggleGlobalMessageVars(topObject) {
	$('#' + topObject).children('span').each(function() {
		if($(this).css('display') === 'none')
			$(this).css('display', '');
		else
			$(this).css('display', 'none');
	});
}

function loadOtherPage(url) {
	if(url.length > 0)
		window.location.href = url;
}

function trim(str) {
	str = str.replace(/^\s\s*/, ''),
		ws = /\s/,
		i = str.length;
	while (ws.test(str.charAt(--i)));
	return str.slice(0, i + 1);
}